<script setup lang="ts">
  import { ref } from "vue"
  import { RouterLink } from 'vue-router'
  import Menubar from 'primevue/menubar'

  const items = ref([
      // {
      //     label: 'Home',
      //     icon: 'pi pi-home',
      //     link: "",
      // },
      {
          label: 'Garden',
          icon: 'pi pi-globe',
          link: "garden"
      },
      {
          label: 'Flowers',
          icon: 'pi pi-star',
          link: "sites",
      },
  ])
</script>

<template>
  <header class="p-2">
    <Menubar :model="items">
      <template #start>
        <RouterLink
          to="/"
          v-ripple
          v-slot="{ href, navigate }">
          <section>
            <a class="dark:text-honey-600 font-bold flex" :href="href" @click="navigate">
              <img
                class="h-12"
                :src="`/hive_full_flat.png`"
                alt="A11y Hive Logo by FreePik"
              />
              <span class="text-2xl py-2 px-4">
                A11y Hive
              </span>
            </a>
          </section>
        </RouterLink>
      </template>
      <template #item="{ item, props }">
        <RouterLink
          :to="`/${item.link}`"
          v-ripple
          class="flex items-center"
          v-slot="{ href, navigate }"
          v-bind="props.action">
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </RouterLink>
      </template>
    </Menubar>
  </header>
</template>

<style scoped lang="scss">

</style>
