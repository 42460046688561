<script setup lang="ts">
  import { ref } from "vue"
  import { RouterLink } from 'vue-router'
  import Menubar from 'primevue/menubar'

  const items = ref([
    {
      label: 'Credits',
      icon: 'pi pi-star',
      link: "credits",
    },
  ])
</script>

<template>
  <footer className="p-2 dark:text-honey-600">
    <Menubar :model="items" :pt="{ item: { class: 'px-4' }}">
      <template #start>
        <a class="p-4" href="http://gala.loreheart.com" target="_blank">
          &copy;2024 LoreHeart
        </a>
      </template>
      <template #item="{ item, props }">
        <RouterLink
          :to="`/${item.link}`"
          v-ripple
          class="flex items-center"
          v-slot="{ href, navigate }"
          v-bind="props.action">
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </RouterLink>
      </template>
    </Menubar>
  </footer>
</template>

<style scoped lang="scss"></style>
