import { createApp } from "vue"
import { createPinia } from "pinia" // @ts-ignore
import PrimeVue from 'primevue/config' // @ts-ignore
import GoldenSink from './presets/golden-sink'
import Ripple from 'primevue/ripple'
import ToastService from 'primevue/toastservice'

import App from "./App.vue"
import router from "./router.js"
import "./styles/main.scss"

const pinia = createPinia()

const app = createApp(App)

app.directive('ripple', Ripple)

app.use(router)
  .use(pinia)
  .use(ToastService)
  .use(PrimeVue, {
    unstyled: true,
    ripple: true,
    pt: GoldenSink                            //apply preset
}).mount("#app")
